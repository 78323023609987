import React, { useMemo } from "react";
import { ReactSVG } from "react-svg";
import Info24 from "Assets/icons/info24x24.svg";
import {
    QUANTACO_INFO_BLUE,
    QUANTACO_INFO_BLUE_10,
    QUANTACO_PEACH,
    QUANTACO_PEACH_10,
} from "utils/style-utils";

interface BannerProps {
    text: string;
    type?: "error" | "info";
}

const Banner = ({ text, type = "info" }: BannerProps) => {
    const iconColor = useMemo(() => {
        switch (type) {
            case "error":
                return QUANTACO_PEACH;
            case "info":
            default:
                return QUANTACO_INFO_BLUE;
        }
    }, [type]);

    const backgroundColor = useMemo(() => {
        switch (type) {
            case "error":
                return QUANTACO_PEACH_10;
            case "info":
            default:
                return QUANTACO_INFO_BLUE_10;
        }
    }, [type]);

    return (
        <div
            className="p-4 gap-3 flex items-center w-full rounded"
            style={{
                backgroundColor,
            }}
        >
            <ReactSVG src={Info24} style={{ stroke: iconColor }} />
            <p className="text-sm" style={{ color: iconColor }}>
                {text}
            </p>
        </div>
    );
};

export default Banner;
