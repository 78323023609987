import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { SafeTab } from "./SafeTab";
import { QuantacoLoader } from "../../Components/QuantacoLoader/QuantacoLoader";
import React from "react";
import {
    LocationType,
    ShiftType,
    ShiftTypeLabels,
} from "Pages/CashupReport/CashupReportModel";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";

export const SafeTabWrapper = () => {
    const { venueId, formattedDate } = useVenueSelection();
    const {
        data: safeLocations,
        isLoading,
        isFetching,
    } = useQuery(
        ["collapsableSafeLocations", venueId, formattedDate],
        ({ signal }) =>
            getLocationsQuery(
                [
                    LocationType.safe,
                    LocationType.float,
                    LocationType.office,
                    LocationType.bank,
                ],
                venueId,
                formattedDate,
                signal
            )
    );

    const locationWithShifts = (location: ExtendedLocationItemWithChildren) => {
        // Pop the first sub_location of the safe location
        const poppedSubLocation = location.sub_locations?.[0];
        const shiftTypeCount = (poppedSubLocation as any)?.cashups?.length;

        if (poppedSubLocation && shiftTypeCount > 0) {
            // Clone the sub_location `shiftTypeCount` times with modified location_id and name
            const newSubLocations = Array.from(
                { length: shiftTypeCount },
                (_, index) => {
                    const shiftType = (index + 1) as ShiftType;
                    return {
                        ...poppedSubLocation,
                        location_id: `${
                            poppedSubLocation.location_id.split(" ")[0]
                        } ${shiftType}`,
                        name: ShiftTypeLabels[shiftType],
                    };
                }
            );
            // Add the new sub_locations to the safe location's sub_locations
            location.sub_locations = [...newSubLocations];
        }
        return location;
    };

    const safeLocationsWithShifts = safeLocations?.map((location: any) => {
        if (location.location_type === LocationType.safe) {
            return {
                // call locationWithShifts function and replace whole location object with the result
                ...locationWithShifts(location),
            };
        }
        return location;
    });

    if (safeLocations === undefined || isFetching || isLoading)
        return <QuantacoLoader size={100} />;

    return <SafeTab safeLocations={safeLocationsWithShifts} />;
};
