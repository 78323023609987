import React, { useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { QuantacoLoader } from "../Components/QuantacoLoader/QuantacoLoader";
import { EventType } from "@azure/msal-browser";
import { PusherProvider } from "../hooks/usePusher";
import { useQuery } from "react-query";
import { getLoggedInUser } from "../ApiV2/Helpers/getLoggedInUser";
import {
    ACCESS_DENIED_CONTENT,
    MODAL_GENERIC_ERROR,
} from "../Pages/CashupHome/ExcelTable/utils";
import { useContextModal } from "../hooks/useModal";
import Banner from "../Components/Banner";

export function AuthProtectedRoute({ component: Component, ...rest }: RouteProps) {
    const { instance, accounts, inProgress } = useMsal();
    const { openModal, closeModal } = useContextModal();
    const [authenticated, setAuthenticated] = React.useState(false);
    const [isForbidden, setIsForbidden] = React.useState(false);
    useEffect(() => {
        if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
            // Account selection logic is app dependent. Adjust as needed for different use cases.
            instance.setActiveAccount(instance.getAllAccounts()[0]);
        }
        instance.enableAccountStorageEvents();
        instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS ||
                    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                    event.eventType === EventType.SSO_SILENT_SUCCESS) &&
                //@ts-ignore
                event.payload.account
            ) {
                //@ts-ignore
                instance.setActiveAccount(event.payload.account);
            }
        });
        const getMsToken = async () => {
            const account = instance.getActiveAccount();
            if (account) {
                try {
                    try {
                        const response = await instance.acquireTokenSilent({
                            scopes: ["User.Read", "Application.Read.All"],
                            account: account,
                        });
                        // Store the ms token in localStorage
                        localStorage.setItem("ms_token", response.accessToken);
                        localStorage.setItem(
                            "ms_username",
                            response.account.username
                        );
                    } catch (error) {
                        console.error("Token acquisition failed:", error);
                    }
                    const res = await instance.acquireTokenSilent({
                        scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/.default`],
                        account: account,
                    });
                    // Store the ms token in localStorage
                    localStorage.setItem("access_token", res.accessToken);
                } catch (error) {
                    console.error("Token acquisition failed:", error);
                }
            } else if (inProgress === "none") {
                await instance.loginRedirect();
            }
        };
        getMsToken().then(() => {
            getLoggedInUser()
                .then(() => setAuthenticated(true))
                .catch((error) => {
                    if (error["status_code"] === 403) {
                        setAuthenticated(false);
                        setIsForbidden(true);
                    }
                });
        });
    }, [instance, accounts, inProgress]);

    if (inProgress !== "none" || !accounts || !authenticated) {
        if (isForbidden) {
            return <Banner type="error" text={ACCESS_DENIED_CONTENT} />;
        }
        return <QuantacoLoader size={200} />;
    }

    return (
        <PusherProvider>
            <Route {...rest} component={Component} />
        </PusherProvider>
    );
}
